const Contact = () => {
  return (
    <section id="getintouch" className="pb-16">
      <div className="container">
        <h2 className="flex items-center justify-center text-fiveColor font-[600] text-[2.5rem] mb-8">
          Get in touch
        </h2>
        <div className="md:flex justify-between items-center">
          <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
            <iframe
              title={'map'}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.139588040704!2d-74.21351589999999!3d39.9829858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c16ee1df5d209b%3A0x35aa7f11efc15b10!2sNJ-444%2C%20New%20Jersey%2C%20USA!5e0!3m2!1sen!2sco!4v1693542372401!5m2!1sen!2sco"
              className="border-0 w-full h-full"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center shadow-lg px-4 lg:px-8 py-8">
            <form className="w-full">
              <div className="mb-5">
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="w-full p-3 focus:outline-none rounded-[8px] shadow-lg"
                />
              </div>

              {/*  */}

              <div className="mb-5">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full p-3 focus:outline-none rounded-[8px] shadow-lg"
                />
              </div>

              {/*  */}

              <div className="mb-5">
                <input
                  type="subject"
                  placeholder="Subject"
                  className="w-full p-3 focus:outline-none rounded-[8px] shadow-lg"
                />
              </div>

              {/*  */}

              <div className="mb-5">
                <textarea
                  type="text"
                  rows={3}
                  placeholder="Write your message"
                  className="w-full p-3 focus:outline-none rounded-[8px] shadow-lg"
                />
              </div>

              {/*  */}

              <button className="border border-solid border-fiveColor rounded-[4px] max-h-[40px] hover:bg-sixColor text-fiveColor hover:text-white font-[500] flex items-center gap-2  ease-in duration-700 py-2 px-4">
                <i className="ri-mail-send-line"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
