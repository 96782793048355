'use client';

import { Collapse } from 'react-collapse';

const MethodItem = ({ open, toggle, title, description }) => {
  return (
    <div className="pt-[10px]">
      <div
        className="bg-white shadow-lg py-[25px] px-[50px] flex justify-between items-center cursor-pointer"
        onClick={toggle}
      >
        <h3 className="text-fiveColor font-[700] text-xl">{title}</h3>
        <div className="text-[25px] text-fiveColor">
          {open ? (
            <i className="ri-arrow-up-s-line"></i>
          ) : (
            <i className="ri-arrow-down-s-line"></i>
          )}
        </div>
      </div>

      <Collapse isOpened={open}>
        <div className="bg-white px-[50px] pb-[20px] text-headingColor">
          {description}
        </div>
      </Collapse>
    </div>
  );
};

export default MethodItem;
