'use client';

const Comp = () => {
  return (
    <section id="comp">
      <div className="container lg:pt-5">
        <div className="text-center mx-auto">
          <h2 className="text-fiveColor text-[2.4rem] font-[600] text-center mb-5">
            Key Competences
          </h2>
          <p className="lg:max-w-[600px] lg:mx-auto text-headingColor text-[16px] font-[500] leading-7 antialiased">
            As a bilingual caregiver, my duty is to adapt, adopt and provide supportive guidance to stimulate self-management skills in older people.
          </p>
        </div>

        <div className="grid grid-cols-1 md md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-[30px] mt-[30px] lg:mt-[55px]">
          <div className="py-[30px] px-5">
            <div className="flex items-center justify-center text-4xl xl:text-6xl text-fourColor">
              <i className="ri-graduation-cap-line"></i>
            </div>

            <div className="mt-[30px]">
              <h2 className="text-2xl left-9 font-[700] text-center text-fiveColor">
                The Caretaker
              </h2>
              <p className="text-[16px] leading-7 text-headingColor font-[400] mt-4 text-center">
                Qualified Professional Biologist, experience developed for more
                than 17 years, specific skills and interests with certified
                training in different fields of study such as ecosystems and
                conservation, use of habitats and species.
              </p>

              <div className="w-[44px] h-[44px] rounded-full border border-solid border-fourColor mt-[30px] mx-auto flex items-center justify-center group hover:bg-fiveColor hover:border-none">
                <i className="ri-arrow-right-line"></i>
              </div>
            </div>
          </div>

          <div className="py-[30px] px-5">
            <div className="flex items-center justify-center text-4xl xl:text-6xl text-fourColor">
              <i className="ri-award-line"></i>
            </div>

            <div className="mt-[30px]">
              <h2 className="text-2xl left-9 font-[700] text-center text-fiveColor">
                Quality Management
              </h2>
              <p className="text-[16px] leading-7 text-headingColor font-[400] mt-4 text-center tracking-wide">
                Prepared person, responsible for coordination and development
                with the design, implementation and control of Quality
                Management Systems, improving performance and skills in internal
                findings.
              </p>

              <div className="w-[44px] h-[44px] rounded-full border border-solid border-fourColor mt-[30px] mx-auto flex items-center justify-center group hover:bg-fiveColor hover:border-none">
                <i className="ri-arrow-right-line"></i>
              </div>
            </div>
          </div>

          <div className="py-[30px] px-5">
            <div className="flex items-center justify-center text-4xl xl:text-6xl text-fourColor">
              <i className="ri-presentation-line"></i>
            </div>

            <div className="mt-[30px]">
              <h2 className="text-2xl left-9 font-[700] text-center text-fiveColor">
                Chess Teacher
              </h2>
              <p className="text-[16px] leading-7 text-headingColor font-[400] mt-4 text-center">
                St. Georges School - SGS, private educational institution, of
                British bilingual origin; contributing knowledge and mental
                ability in the game considered Science, academic segments of
                Preschool and Primary.
              </p>

              <div className="w-[44px] h-[44px] rounded-full border border-solid border-fourColor mt-[30px] mx-auto flex items-center justify-center group hover:bg-fiveColor hover:border-none">
                <i className="ri-arrow-right-line"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comp;
