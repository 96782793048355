'use client';

import { useEffect, useRef } from 'react';

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const stickyHeaderFunc = () => {
    window.addEventListener('scroll', () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add('sticky__header');
      } else {
        headerRef.current.classList.remove('sticky__header');
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();

    return window.removeEventListener('scroll', stickyHeaderFunc);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();

    const targetAttr = e.target.getAttribute('href');
    const location = document.querySelector(targetAttr).offsetTop;

    window.scrollTo({
      top: location - 80,
      left: 0,
    });
  };

  const toggleMenu = () => menuRef.current.classList.toggle('show__menu');

  return (
    <header
      ref={headerRef}
      className="w-full h-[80px] leading-[80px] flex items-center"
    >
      <div className="container">
        <div className="flex items-center justify-between">
          {/*===LOGO===*/}
          <div className="flex items-center gap-[10px]">
            <span className="w-[35px] h-[35px] bg-sixColor text-white text-[18px] font-[500] rounded-full flex items-center justify-center">
              A
            </span>

            <div className="leading-[20px]">
              <h2 className="text-xl text-sixColor font-[700]">
                Always at Home
              </h2>
              <p className="text-fiveColor text-[14px] font-[500]">
                New Jersey U.S.A
              </p>
            </div>
          </div>
          {/*===END LOGO===*/}

          {/*===MENU START===*/}
          <div className="menu" ref={menuRef} onClick={toggleMenu}>
            <ul className="flex items-center gap-10">
              <li>
                <a
                  onClick={handleClick}
                  className="text-sixColor font-[600]"
                  href="#standar"
                >
                  Standar
                </a>
              </li>
              <li>
                <a
                  onClick={handleClick}
                  className="text-sixColor font-[600]"
                  href="#method"
                >
                  Method
                </a>
              </li>
              <li>
                <a
                  onClick={handleClick}
                  className="text-sixColor font-[600]"
                  href="#comp"
                >
                  Competence
                </a>
              </li>
              <li>
                <a
                  onClick={handleClick}
                  className="text-sixColor font-[600]"
                  href="#options"
                >
                  Options
                </a>
              </li>
              {/* <li>
                <a
                  onClick={handleClick}
                  className="text-sixColor font-[600]"
                  href="#getintouch"
                >
                  Get in touch
                </a>
              </li> */}
            </ul>
          </div>
          {/*===END MENU===*/}

          {/*===MENU RIGHT===*/}
          <div className="flex items-center gap-4">
            <a href="#getintouch">
              <button className="border border-solid border-fiveColor rounded-[4px] max-h-[40px] hover:bg-sixColor text-fiveColor hover:text-white font-[500] flex items-center gap-2  ease-in duration-700 py-2 px-4">
                <i className="ri-mail-send-line"></i>
              </button>
            </a>
            <span
              onClick={toggleMenu}
              className="text-2xl text-fiveColor md:hidden cursor-pointer"
            >
              <i className="ri-menu-line"></i>
            </span>
          </div>
          {/*===END MENU RIGHT===*/}
        </div>
      </div>
    </header>
  );
};

export default Header;
