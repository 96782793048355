import imgMtd1 from '../../assets/image/method1.svg';

import { useState } from 'react';
import AccordionItem from './MethodItem';

const Method1 = () => {
  const [open, setOpen] = useState(false);

  const toggle = (index) => {
    if (open === index) {
      return setOpen(null);
    }

    setOpen(index);
  };

  const method = [
    {
      title: 'What is ?',
      description:
        'Based on leading-edge scientific research on the mind and designed in activities to prevent the onset of new forms of cognitive decline, as well as slow the progression of existing symptoms in people experiencing cognitive decline.',
    },
    {
      title: 'Executive Functioning',
      description:
        'Includes cognitive abilities such as reasoning, problem solving, judgment, and thought flexibility.',
    },
    {
      title: 'Attention',
      description:
        'Refers to the ability to focus on a specific piece of information for a long period of time while ignoring competing distractions.',
    },
    {
      title: 'Language',
      description:
        'Refers to the ability to execute verbal functions including spontaneous speech, speech repetition, speech comprehension, naming, reading and writing.',
    },
    {
      title: 'Visual-Spatial Perception',
      description:
        'Involves the ability to accurately perceive and object’s physical location and understand the relationships between objects.',
    },
    {
      title: 'Memory',
      description:
        'Refers to the ability to retain information and utilize it later',
    },
  ];

  return (
    <section id="method1">
      <div className="container">
        <div className="flex justify-between gap-[50px] lg:gap-0 items-center">
          <div className="w-full md:w-1/2">
            <h2 className="text-fiveColor text-[2.4rem] font-[600] text-center justify-center mb-5">
              Cognitive Therapeutics Method
            </h2>

            {method.map((data, index) => {
              return (
                <AccordionItem
                  key={index}
                  open={index === open}
                  title={data.title}
                  description={data.description}
                  toggle={() => toggle(index)}
                />
              );
            })}
          </div>

          <div className="w-1/2 hidden md:flex justify-end">
            <img src={imgMtd1} alt="method1" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Method1;
