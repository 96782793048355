import imgHero3 from '../../assets/image/hero3.png';

const Hero = () => {
  return (
    <section className="pt-0" id="standar">
      <div className="container pt-14">
        <div className="md:flex items-center justify-center sm:flex-col md:flex-row">
          {/*===HERO LEFT CONTENT===*/}
          <div className="w-full text-center md:basis-1/2">
            <h5
              /* data-aos="fade-right"
              data-aos-duration="1500" */
              className="text-sixColor font-[600] text-xl"
            >
              Senior Support Services
            </h5>
            <h1
              /* data-aos="fade-up"
              data-aos-duration="1500" */
              className="text-headingColor font-[600] text-[1.8rem] sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5"
            >
              Keeping the care <br />
              of your loved one <br /> 
              <span className="text-fourColor">at home</span> is possible!
            </h1>

            <div
              /* data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-delay="200" */
              className="flex items-center justify-center gap-6 mt-7"
            >
              <a href="#method">
                <button
                  className="border border-solid border-fiveColor rounded-[4px] hover:bg-sixColor text-fiveColor hover:text-white text-[15px] font-[500] flex items-center gap-2
                  ease-in duration-700 py-2 px-4"
                >
                  <i className="ri-open-arm-line"></i>Balanced Care
                </button>
              </a>
              <a
                href="#method1"
                className="text-fiveColor font-[500] text-[15px] border-b border-solid border-fiveColor flex items-center gap-2 py-2 px-4"
              >
                <i className="ri-brain-line"></i>Cognitive Care
              </a>
            </div>
            <p
              /* data-aos="fade-left"
              data-aos-duration="1500" */
              className="flex lg:max-w-[600px] lg:mx-auto items-center justify-center gap-2 text-headingColor mt-14 font-[500] text-[15px] leading-7 "
            >
              Recognizing the positive in every situation is thinking, speaking
              and acting from the positive position.
            </p>

            <div className="w-full flex items-center justify-center mt-10">
              <figure className="">
                <img src={imgHero3} alt="hero3" />
              </figure>
            </div>
            <div className="flex items-center justify-center gap-9 mt-14">
              <span className="text-sixColor text-[15px] font-[600]">
                Ask me
              </span>
              <span>
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[18px] font-[600]"
                >
                  <i className="ri-computer-line"></i>
                </a>
              </span>
              <span>
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[18px] font-[600]"
                >
                  <i className="ri-macbook-line"></i>
                </a>
              </span>
              <span>
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[18px] font-[600]"
                >
                  <i className="ri-tablet-line"></i>
                </a>
              </span>
              <span>
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[18px] font-[600]"
                >
                  <i className="ri-smartphone-line"></i>
                </a>
              </span>
            </div>
          </div>
          {/*===END HERO LEFT CONTENT===*/}
          {/* HERO img */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
