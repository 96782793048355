import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const data = [
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'Good morning Maria, I just wanted to share that my sister-in-law went to see mom. She said how wonderful the house looked and how nice mommy looked and that you were taking such good care of her. She´s so glad you´re staying with her... So on behalf of all of us, we thank you. 10/10/21',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message: 'thank you very much you are the best 12/30/21',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'Thank you for taking such good care of my mother, she really appreciates you and all of us, you are doing an exceptional job. I just want you to know that we really appreciate you. 2/7/21',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'Thank you for always being on the ball and making sure everything is right with Mom. We appreciate you so much and you re doing a great job. She can be a little tough sometimes. 7/24/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'Good morning. Thank you so much for being able to stay with Mom. Shes so comfortable with you. 8/12/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'We re so grateful that you re willing and I know mom will feel more at peace with you there... Thank you so much. 8/12/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'We really do appreciate all you do and are doing for Mom. 8/12/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'Thank you so much. We all appreciate you more than you know and you do a wonderful thing for Mom. 8/13/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'Good morning. Are you coming to Moms today. Shes asking if youll be there. 8/15/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message: 'Thank you for such a detailed update we love you. 8/16/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message: 'Thats great news, thank you so much. 8/16/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message: 'Thats wonderful! Thank you for taking such good care of her. 8/16/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message: 'Oh thats good to hear....thank you for updating always. 8/17/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message:
      'Thanks for the update I think shes doing great also thank you so much for all you do. 8/17/23',
  },
  {
    image: '/avatar.avif',
    name: '',
    position: '',
    message: 'Such great news! Thank you for keeping us updated. 8/17/23',
  },
];

const FeedSlider = () => {
  return (
    <Swiper
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="h-[500px]"
    >
      {data.map(({ /* image, */ name, position, message }, index) => {
        return (
          <SwiperSlide key={index}>
            <div className="flex flex-col items-center md:flex-row gap-x-8 h-full px-16">
              {/* avatar, name, position */}
              <div className="w-full max-w-[300px] flex flex-col xl:justify-center items-center relative mx-auto xl:mx-0">
                <div className="flex flex-col justify-center text-center">
                  {/* avatar */}
                  <div className="mb-2 text-4xl xl:text-6xl text-fourColor mx-auto md:mx-0">
                    <i className="ri-user-star-line"></i>
                    {/* <Image
                      src={image}
                      alt="image"
                      width={100}
                      height={100}
                      priority={false}
                    /> */}
                  </div>
                  {/* name */}
                  <div className="text-[16px] text-headingColor">{name}</div>
                  {/* position */}
                  <div className="text-[13px] lowercase font-extralight tracking-widest text-fiveColor">
                    {position}
                  </div>
                </div>
              </div>
              {/* quote & message */}
              <div className="flex-1 flex flex-col justify-center before:w-[1px] xl:before:bg-threeColor xl:before:absolute xl:before:left-0 xl:before:h-[200px] relative xl:pl-0 text-headingColor shadow-lg ms-6">
                {/* quote icon */}
                <div className="mb-4 text-4xl xl:text-4xl text-fourColor mx-auto md:mx-0">
                  <i className="ri-chat-quote-line"></i>
                </div>
                {/* message */}
                <div className="text-[16px] text-center">{message}</div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default FeedSlider;
