import OptionsPlans from './OptionsPlans';

const Options = () => {
  return (
    <section id="options">
      <div className="container lg:pt-3">
        <div className="text-center mx-auto">
          <h2 className="text-fiveColor font-[600] text-[2.4rem] mb-5">
            Coordinate your Options
          </h2>
          <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7 antialiased">
            Meet unique needs with competitive flexibility in attention and
            schedules.
          </p>
        </div>

        <OptionsPlans />
      </div>
    </section>
  );
};

export default Options;
