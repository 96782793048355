import CTA from './CTA';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-threeColor pt-12">
      {/* footer top */}

      <div className="container">
        <div className="sm:flex items-center justify-between md:gap-8">
          <div className="w-full sm:w-1/2">
            <h2 className="text-[26px] leading-10 text-white font-[600] mb-5 md:text-[2rem]">
              Caring for those who once cared for us is a state in which
              something does matter.
            </h2>
            <a href="#getintouch">
              <button className="border border-solid border-fiveColor rounded-[4px] max-h-[40px] hover:bg-sixColor text-fiveColor hover:text-white font-[500] flex items-center gap-2  ease-in duration-700 py-2 px-4">
                <i className="ri-mail-send-line"></i> inquery!
              </button>
            </a>
          </div>

          {/*  */}
          <div className="w-full sm:w-1/2">
            <p className="text-fiveColor leading-7 mt-4 sm:mt-0">
              I believe that we all have the potential to improve well-being and
              quality of life at every stage. <br />
              We know the elderly as a person, not as patients.
            </p>

            <div className="flex items-center gap-4 flex-wrap md:gap-8 mt-10">
              <span className="text-fiveColor font-[600] text-[15px]">
                Devices
              </span>

              <span className="w-[35px] h-[35px] bg-white p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[15px] font-[600]"
                >
                  <i className="ri-computer-line"></i>
                </a>
              </span>

              <span className="w-[35px] h-[35px] bg-white p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[15px] font-[600]"
                >
                  <i className="ri-macbook-line"></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-white p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[15px] font-[600]"
                >
                  <i className="ri-tablet-line"></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-white p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#getintouch"
                  className="text-fiveColor text-[15px] font-[600]"
                >
                  <i className="ri-smartphone-line"></i>
                </a>
              </span>
            </div>
          </div>
        </div>

        <div>
          <ul className="flex items-center justify-center gap-6 mt-10">
            <li>
              <a className="text-sixColor font-[600]" href="#standar">
                Standar
              </a>
            </li>
            <li>
              <a className="text-sixColor font-[600]" href="#method">
                Method
              </a>
            </li>
            <li>
              <a className="text-sixColor font-[600]" href="#comp">
                Competence
              </a>
            </li>
            <li>
              <a className="text-sixColor font-[600]" href="#options">
                Options
              </a>
            </li>
            {/* <li>
              <a className="text-sixColor font-[600]" href="#feedbacks">
                Feed
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      {/* END FOOTER TOP */}

      {/* FOOTER BOTTOM */}
      <div className="bg-twoColor py-5 mt-14">
        <div className="container">
          <div className="flex items-center justify-center sm:justify-between">
            <div className="hidden sm:block">
              <div className="flex items-center gap-[10px]">
                {/* <span className="w-[35px] h-[35px] rounded-full bg-sixColor text-white font-[500] text-[18px] flex items-center justify-center">
                  A */}
                  <CTA/>
                {/* </span> */}

                <div className="leading-[20px]">
                  <h2 className="text-sixColor font-[500] text-[18px]">
                    Always at Home
                  </h2>
                  <p className="text-fiveColor text-[14px] font-[500]">
                    New Jersey U.S.A
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p className="text-gray-600 text-[14px]">
                © developed by codevscript {year}{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* END FOOTER BOTTOM */}
    </footer>
  );
};

export default Footer;
