'use client';

import FeedSlider from './FeedSlider';

const Feed = () => {
  return (
    <section id="feedbacks">
      <div className="container mx-auto h-full flex flex-col justify-center lg:pt-5">
        <div className="h-full  text-center">
          <h2 className="text-fiveColor font-[600] text-[2.5rem] mb-8">
            My clients SAY
          </h2>

          <FeedSlider />
        </div>
      </div>
    </section>
  );
};

export default Feed;
