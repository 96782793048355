import optionsPlans from '../../assets/data/optnData';

const OptionsPlans = () => {
  return (
    <div className="mx-auto grid max-w-7xl gap-12 lg:gap-8 py-24 px-4 sm:px-6 lg:grid-cols-3 lg:px-8">
      {optionsPlans.map((plan) => (
        <div
          key={plan.title}
          className="relative flex rounded-[8px] bg-white p-8 shadow-lg flex-col" /* border border-slate-200 */
        >
          <h3 className="text-fiveColor font-[700] text-2xl leading-7">
            {plan.title}
          </h3>
          {plan.mostPopular && (
            <p className="absolute top-0 -translate-y-1/2 bg-fourColor text-white px-3 py-0.5 text-sm font-semibold tracking-wide rounded-[8px] shadow-md">
              Better option
            </p>
          )}
          <p className="mt-4 text-headingColor text-[16px] ">
            {plan.description}
          </p>
          <div className="mt-4 bg-twoColor p-6 rounded-lg -mx-6">
            <p className="flex text-sm font-[700] text-headigColor items-center">
              <span>{plan.currency}</span>
              <span className="ml-3 text-4xl text-fiveColor">
                ${plan.price}
              </span>
              <span className="ml-1.5">{plan.frecuency}</span>
            </p>
          </div>
          {/* FEATURES */}
          <ul className="mt-6 space-y-4 flex-1">
            {plan.features.map((feature) => (
              <li
                key={feature}
                className="font-[500] text-[16px] leading-7 text-headingColor flex"
              >
                <svg
                  className="h-5 w-5 shrink-0"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.75L3.36923 7L8 1"
                    stroke="#A569BD"
                    strokeLinecap="round"
                  />
                </svg>
                <span className="ml-3">{feature}</span>
              </li>
            ))}
          </ul>
          {/* Call to action */}
          <a
            href="#options"
            className={`gap-2 mt-8 block ease-in duration-700 px-4 py-2 text-center font-[500] leading-4 rounded-[4px] max-h-[40px]  
            
            ${
              plan.mostPopular
                ? 'border border-solid text-white border-twoColor bg-fiveColor hover:bg-twoColor hover:text-fiveColor shadow-md'
                : 'border border-solid text-fiveColor border-twoColor bg-twoColor hover:bg-fiveColor hover:text-white shadow-md'
            }
            
            `}
          >
            {plan.cta}
          </a>
        </div>
      ))}
    </div>
  );
};

export default OptionsPlans;
