import imgFig from '../../assets/image/fig.png';

const Standar = () => {
  return (
    <section id="standar">
      <div className="container lg:pt-5">
        <div className="text-center mx-auto">
          <h2 className="text-fiveColor font-[600] text-[2.4rem] mb-5">
            Standards of excellence
          </h2>
          <p className="lg:max-w-[600px] lg:mx-auto text-headingColor text-[16px] leading-7 font-[500] antialiased">
            Keeping the elderly at home poses some challenges, but there are
            many different services that can minimize the family burden,
            increase the independence of the elderly, improve their security and
            confidence, helping them at this stage of their lives to live
            successfully at home. We practice conscious communication, with
            attention and focus centered on the person.
          </p>
        </div>

        <div className="flex flex-col justify-center sm:py-12">
          <div className="w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
            <div className="relative text-headingColor antialiased text-sm font-semibold">
              {/* VERTICAL LINE RUNNING THROUGH THE MIDDLE */}
              <div className="hidden absolute w-1 sm:block bg-threeColor h-full left-1/2 transform -translate-x-1/2"></div>

              {/* LEFT CARD 1 */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        /* data-aos="fade-right"
                        data-aos-duration="1200" */
                        className="bg-white p-4 rounded shadow-lg group hover:bg-threeColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-fiveColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          1.
                        </h3>
                        <p className="text-[15px] text-headingColor group-hover:text-white group-hover:font-[500] leading-7">
                          Involve older people and their families by talking
                          with them, planning and organizing the new routine.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-gray-200 border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={imgFig} alt="standar" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* END LEFT CARD 1 */}

              {/* RIGHT CARD 1*/}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        /* data-aos="fade-left"
                        data-aos-delay="50"
                        data-aos-duration="1300" */
                        className="bg-white p-4 rounded shadow-lg group hover:bg-fourColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-fiveColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          2.
                        </h3>
                        <p className="text-[15px] text-headingColor group-hover:text-white group-hover:font-[500] leading-7">
                          Adequacy of meals and nutrition; Taking into account
                          the restrictions, recommended diets and healthy
                          preferences.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-gray-200 border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={imgFig} alt="standar" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* END RIGHT CARD 1*/}

              {/* LEFT CARD 2 */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        /* data-aos="fade-right"
                        data-aos-delay="100"
                        data-aos-duration="1400" */
                        className="bg-white p-4 rounded shadow-lg group hover:bg-threeColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-fiveColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          3.
                        </h3>
                        <p className="text-[15px] text-headingColor group-hover:text-white group-hover:font-[500] leading-7">
                          Genuine interest in senior and family members
                          listening to their needs, making sure that their
                          environment is clean and safe.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-gray-200 border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={imgFig} alt="standar" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* END LEFT CARD 2 */}

              {/* RIGHT CARD 2*/}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        /* data-aos="fade-left"
                        data-aos-duration="1500" */
                        className="bg-white p-4 rounded shadow-lg group hover:bg-threeColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-fiveColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          4.
                        </h3>
                        <p className="text-[15px] text-headingColor group-hover:text-white group-hover:font-[500] leading-7">
                          Monitoring senior, recording and reporting any
                          developments or concerns to appropriate parties or
                          family members.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-gray-200 border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={imgFig} alt="standar" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* END RIGHT CARD 2*/}

              {/* LEFT CARD 3 */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        /* data-aos="fade-right"
                        data-aos-duration="1200" */
                        className="bg-white p-4 rounded shadow-lg group hover:bg-threeColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-fiveColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          5.
                        </h3>
                        <p className="text-[15px] text-headingColor group-hover:text-white group-hover:font-[500] leading-7">
                          Adhering to any medication regimen prescribed by your
                          doctor is extremely important for seniors. <br />
                          <span className="text-fiveColor">
                            We do NOT administer MEDICATIONS
                          </span>
                          , we can provide reminders, read labels, and help open
                          packages for seniors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-gray-200 border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={imgFig} alt="standar" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* END LEFT CARD 3 */}

              {/* RIGHT CARD 3*/}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-auto items-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        /* data-aos="fade-left"
                        data-aos-duration="1600" */
                        className="bg-white p-4 rounded shadow-lg group hover:bg-threeColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-fiveColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          6.
                        </h3>
                        <p className="text-[15px] text-headingColor group-hover:text-white group-hover:font-[500] leading-7">
                          The conversation with a prepared, kind and friendly
                          caregiver provides important emotional support to the
                          elderly; it keeps him mentally active and engaged as
                          they deal with her prospects and conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-full bg-gray-200 border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center">
                    <figure>
                      <img src={imgFig} alt="standar" />
                    </figure>
                  </div>
                </div>
              </div>
              {/* END RIGHT CARD 3*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Standar;
