const optns = [
    {
        title: 'Daily',
        price: 22,
        currency: 'USD',
        frecuency: '/hour',
        description: 'Basic Care',
        features: [
            'Support at home',
            'Primary Care',
            'Emotional care',
        ],
        cta: 'Start your care',
        mostPopular: false,
    },
    {
        title: 'Weekly',
        price: 'to agree',
        /* currency: 'USD', */
        frecuency: '/hour',
        description: 'Premium Care',
        features: [
            'Complete Care',
            'Complete Support at home',
            'Comprehensive Emotional Care',
            'Meaningful activities and conversation',
            
        ],
        cta: 'Start your care',
        mostPopular: true,
    },
    {
        title: 'Monthly',
        price: 'to agree',
        /* currency: 'USD', */
        frecuency: '/hour',
        description: 'Comprehensive Care',
        features: [
            'Monitoring',
            'Comprehensive care',
            'Home help',
            'Healthy eating time',
            'Comprehensive Emotional Care',
            'Meaningful activities and conversation',
            

        ],
        cta: 'Start your care',
        mostPopular: false,
    },
]

export default optns;