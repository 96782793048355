import React from 'react'
import FORM from '../../assets/image/form.pdf'

const CTA = () => {
  return (
    <div className='cta'>
      <span className="w-[35px] h-[35px] rounded-full bg-sixColor text-white font-[500] text-[18px] flex items-center justify-center">
                  
        <a href={FORM} download className='btn'>A</a>
</span>
        {/* <a href="#contact" className='btn btn-primary'></a> */}
    </div>
  )
}

export default CTA
