import imgMtd from '../../assets/image/method.svg';

import { useState } from 'react';
import AccordionItem from './MethodItem';

const Method = () => {
  const [open, setOpen] = useState(false);

  const toggle = (index) => {
    if (open === index) {
      return setOpen(null);
    }

    setOpen(index);
  };

  const method = [
    {
      title: 'What is ?',
      description:
        'The Balanced Care Method is a program based on scientific evidence and built on studies that show that only one third of our healthy longevity is based on genetics and two thirds on lifestyle factors that are under our control.',
    },
    {
      title: 'Healthy Diet',
      description:
        'Focus nutrition based on vegetables, fruits, legumes and proteins, adequate and balanced portions of high value in macronutrients, encouraging older people to be attentive at mealtime. We include the elderly and family members in menu planning, turning nutrition into a pleasant opportunity for participation.',
    },
    {
      title: 'Physical Activity',
      description:
        'One in three people over the age of 75 does not engage in any physical activity. This contributes to falls, reduced bone density, and health problems. We adapt physical activity in a safe way taking into account individual or pre-existing limitations. Finding every opportunity to get up and move.',
    },
    {
      title: 'Sharp Minds',
      description:
        'High-quality sleep is important, regulating mood and cognitive function. I prioritize establishing and maintaining healthy sleep habits at the right time in a quiet environment during the day; Limiting naps increases acute stimulation, leading to a deeper, healthier sleep at night.',
    },
    {
      title: 'Social Ties',
      description:
        'Only if possible and in their safe environment can we see an opportunity for the older adult to engage in conversation, discover new hobbies, and stay connected with family, friends, and the community.',
    },
    {
      title: 'Calm and Purpose',
      description:
        'An older adult when they are calm and in harmony have a purpose, they are better equipped to avoid stress and illness. We seek to find joy and meaning through activities like yoga, meditation, bird watching, and even simple activities like listening to music.',
    },
  ];

  return (
    <section id="method">
      <div className="container">
        <div className="flex justify-between gap-[50px] lg:gap-0 items-center">
          <div className="w-1/2 hidden md:flex justify-start">
            <img src={imgMtd} alt="method" />
          </div>

          <div className="w-full md:w-1/2">
            <h2 className="text-fiveColor text-[2.4rem] font-[600] text-center justify-center mb-5">
              Balanced care Method
            </h2>

            {method.map((data, index) => {
              return (
                <AccordionItem
                  key={index}
                  open={index === open}
                  title={data.title}
                  description={data.description}
                  toggle={() => toggle(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Method;
