import Aos from 'aos';
import { useEffect } from "react";

import './App.css';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Comp from './components/UI/Comp';
import Contact from './components/UI/Contact';
import Feed from './components/UI/Feed';
import Hero from './components/UI/Hero';
import Method from './components/UI/Method';
import Method1 from './components/UI/Method1';
import Options from './components/UI/Options';
import Standar from './components/UI/Standar';

function App() {

  useEffect(() => {
    Aos.init()
  }, []);


  return (
    <>
      <Header />
      <main>
        <Hero />
        <Standar />
        <Method />
        <Method1 />
        <Comp />
        <Options />
        <Feed />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default App;
